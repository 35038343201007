<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M5.67 22.63H.81V8.7h4.86Zm10.09-9.39l-.01 0c-1.19 0-2.14.95-2.14 2.14v7.25H8.5V8.7h5.11v1.59l0-.01c1.19-1.02 2.71-1.58 4.28-1.6 3.17 0 5.37 2.35 5.37 6.81v7.13h-5.37v-7.25l0-.01c0-1.19-.95-2.15-2.13-2.15 -.01-.01-.01-.01-.02-.01Zm-10-9.36v0c0 1.38-1.12 2.5-2.5 2.5 -1.39 0-2.5-1.12-2.5-2.5 0-1.39 1.11-2.5 2.5-2.5l0 0c1.37.01 2.48 1.12 2.48 2.5Z"
    />
  </svg>
</template>
